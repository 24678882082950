// Generated by Framer (1119174)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {B4KrAjtaQ: {hover: true}, Gic3JCo6p: {hover: true}};

const cycleOrder = ["Gic3JCo6p", "B4KrAjtaQ"];

const serializationHash = "framer-usxCe"

const variantClassNames = {B4KrAjtaQ: "framer-v-2fv8nc", Gic3JCo6p: "framer-v-1steyvk"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"New Tab": "B4KrAjtaQ", "Same Tab": "Gic3JCo6p"}

const getProps = ({height, id, link, title, width, ...props}) => { return {...props, AqFLEyrCT: title ?? props.AqFLEyrCT ?? "Button", MZHNtHQDX: link ?? props.MZHNtHQDX, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "Gic3JCo6p"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, AqFLEyrCT, MZHNtHQDX, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "Gic3JCo6p", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-usxCe", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={MZHNtHQDX} openInNewTab={false} {...addPropertyOverrides({B4KrAjtaQ: {openInNewTab: true}}, baseVariant, gestureVariant)}><motion.a {...restProps} className={`${cx("framer-1steyvk", className)} framer-rraklr`} data-framer-name={"Same Tab"} layoutDependency={layoutDependency} layoutId={"Gic3JCo6p"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-1d8ee444-a3c2-4c0d-8e4e-e18aac55148f, rgb(42, 103, 247))", borderBottomLeftRadius: 4, borderBottomRightRadius: 4, borderTopLeftRadius: 4, borderTopRightRadius: 4, opacity: 1, ...style}} variants={{"B4KrAjtaQ-hover": {opacity: 0.8}, "Gic3JCo6p-hover": {opacity: 0.8}}} {...addPropertyOverrides({"B4KrAjtaQ-hover": {"data-framer-name": undefined}, "Gic3JCo6p-hover": {"data-framer-name": undefined}, B4KrAjtaQ: {"data-framer-name": "New Tab"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "SW50ZXItU2VtaUJvbGQ=", "--framer-font-family": "\"Inter-SemiBold\", \"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "18px", "--framer-font-weight": "600", "--framer-line-height": "1.75em", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-ac29c41b-fc04-4e69-be05-8b5eeb3b25ed, rgb(250, 250, 250)))"}}>Back to Home Page</motion.p></React.Fragment>} className={"framer-5hbeaq"} fonts={["Inter-SemiBold"]} layoutDependency={layoutDependency} layoutId={"ef3LOa2qY"} style={{"--extracted-r6o4lv": "var(--token-ac29c41b-fc04-4e69-be05-8b5eeb3b25ed, rgb(250, 250, 250))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={AqFLEyrCT} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-usxCe [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-usxCe .framer-rraklr { display: block; }", ".framer-usxCe .framer-1steyvk { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: auto; justify-content: center; overflow: hidden; padding: 8px 16px 8px 16px; position: relative; text-decoration: none; width: auto; will-change: var(--framer-will-change-override, transform); }", ".framer-usxCe .framer-5hbeaq { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-usxCe .framer-1steyvk { gap: 0px; } .framer-usxCe .framer-1steyvk > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-usxCe .framer-1steyvk > :first-child { margin-top: 0px; } .framer-usxCe .framer-1steyvk > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 48
 * @framerIntrinsicWidth 90
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"B4KrAjtaQ":{"layout":["auto","auto"]},"zzjdxPfjJ":{"layout":["auto","auto"]},"lt_cApHWr":{"layout":["auto","auto"]}}}
 * @framerVariables {"AqFLEyrCT":"title","MZHNtHQDX":"link"}
 * @framerImmutableVariables true
 */
const Framerv544_jfJg: React.ComponentType<Props> = withCSS(Component, css, "framer-usxCe") as typeof Component;
export default Framerv544_jfJg;

Framerv544_jfJg.displayName = "Primary Button";

Framerv544_jfJg.defaultProps = {height: 48, width: 90};

addPropertyControls(Framerv544_jfJg, {variant: {options: ["Gic3JCo6p", "B4KrAjtaQ"], optionTitles: ["Same Tab", "New Tab"], title: "Variant", type: ControlType.Enum}, AqFLEyrCT: {defaultValue: "Button", displayTextArea: false, title: "Title", type: ControlType.String}, MZHNtHQDX: {title: "Link", type: ControlType.Link}} as any)

addFonts(Framerv544_jfJg, [])